import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {Observable, BehaviorSubject} from 'rxjs';

import {Storage} from '@ionic/storage';
import {User} from './user';
import {AuthResponse} from './auth-response';
import {EnvService} from '../services/env.service';
import {Router} from '@angular/router';

class HttpParamsOptions {
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private  httpClient: HttpClient, private  storage: Storage, private env: EnvService, private router: Router) {
    }

    authSubject = new BehaviorSubject(false);

    register(user: User): Observable<AuthResponse> {
        return this.httpClient.post<AuthResponse>(`${this.env.API_URL}/api/users`, user).pipe(
            tap(async (res: AuthResponse) => {
            })
        );
    }

    login(data): Observable<AuthResponse> {
        return this.httpClient.post(`${this.env.API_URL}/oauth/token`, data).pipe(
            tap(async (res: AuthResponse) => {
                localStorage.setItem('currentUser', JSON.stringify(res));
            })
        );
    }

    getUserInfos(token = null) {
        var headers = this.env.headers;
        if (token != null) {
            headers = new HttpHeaders({
                'Authorization': 'Bearer ' + token
            });
        }
        return this.httpClient.get(this.env.API_URL + '/api/user', {headers: headers}).pipe(map(user => {
            localStorage.setItem('user', JSON.stringify(user));
        }));
    }

    logout() {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('user');
        this.router.navigateByUrl('/home');
    }

    isLoggedIn() {
        return (localStorage.getItem('currentUser') ? true : false);
    }
}
