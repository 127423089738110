import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MenuController } from '@ionic/angular';
import {AuthService} from './auth/auth.service';
import {ContactPage} from './contact/contact.page';
import {HomePage} from './home/home.page';
import {LoginPage} from './auth/login/login.page';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private menu:MenuController,
    private authService: AuthService,
    private router: Router
  ) {
    this.initializeApp();
  }

  public cartsProducts;

  initializeApp() {
    if(!localStorage.getItem('cart')) {
      localStorage.setItem('cart', '[]');
    }
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  closeMenu() {
    this.menu.close(); //force full close the menu
  }

  isLogged() {
    return this.authService.isLoggedIn();
  }

  openCart() {
    this.getCartsProducts();
    this.menu.enable(true, 'cart');
    this.menu.open('cart');
  }

  closeCartMenu() {
    this.menu.close('cart');
  }

  articlesInCart() {
    return JSON.parse(localStorage.getItem('cart')).length;
  }

  getCartsProducts() {
    this.cartsProducts = JSON.parse(localStorage.getItem('cart'));
  }

  transparency() {
    if(this.router.url === '/seller/scanner') {
      return 'transparent';
    }
  }

  removeCartProduct(product) {
    let tmpCart = JSON.parse(localStorage.getItem('cart'));
    var finalCart = [];
    tmpCart.forEach(obj => {
      if(obj.id != product.id) {
        finalCart.push(obj);
      }
    })
    localStorage.setItem('cart', JSON.stringify(finalCart));
    this.getCartsProducts()
  }

  date = new Date();
}
