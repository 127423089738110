import {Injectable} from '@angular/core';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import { environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EnvService {
    API_URL = 'https://evadego.com:8080';
    STORAGE_URL = 'https://evadego.com:8080/storage/';
    CLIENT_ID = '1';
    CLIENT_SECRET = '8ASCAfsunBZ0hFqrT5APGsvv3a55o2b1n6Bnst74';

    headers = new HttpHeaders({
        'Content': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + this.get_access_token()
    });
    headersImg = new HttpHeaders({
        'Authorization': 'Bearer ' + this.get_access_token()
    });
    params = new HttpParams();

    constructor() {
    }

    get_access_token() {
        if (JSON.parse(localStorage.getItem('currentUser'))) {
            return JSON.parse(localStorage.getItem('currentUser')).access_token;
        }
    }
}
