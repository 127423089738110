import {Component, OnInit} from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {HttpClient} from '@angular/common/http';
import {EnvService} from '../../services/env.service';
import {stringify} from 'querystring';

@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.page.html',
    styleUrls: ['./product-detail.page.scss'],
})
export class ProductDetailPage implements OnInit {

    product;

    constructor(private navCtrl: NavController, private modalCtrl: ModalController, private storage: Storage, private  httpClient: HttpClient, public env: EnvService) {
    }

    ngOnInit() {
        this.product.products.forEach(obj => {
            if(!this.product.total) {
                this.product.total = 0;
            }
            this.product.total = this.product.total + (obj.quantity * obj.product_price);
        });
    }

    async closeModal() {
        await this.modalCtrl.dismiss();
    }

}
