import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {path: '', redirectTo: 'home', pathMatch: 'full'},
    {path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)},
    {
        path: 'shop/:shopId/:shopName',
        loadChildren: () => import('./shop/shop.module').then(m => m.ShopPageModule)
    },
    {
        path: 'register',
        loadChildren: () => import('./auth/register/register.module').then(m => m.RegisterPageModule)
    },
    {
        path: 'login',
        loadChildren: () => import('./auth/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'shop-list',
        loadChildren: () => import('./shop-list/shop-list.module').then(m => m.ShopListPageModule)
    },
    {
        path: 'buyer/home',
        loadChildren: () => import('./buyer/home/home.module').then(m => m.HomePageModule)
    },
    {
        path: 'buyer/profil',
        loadChildren: () => import('./buyer/profil/profil.module').then(m => m.ProfilPageModule)
    },
    {
        path: 'buyer/history',
        loadChildren: () => import('./buyer/history/history.module').then(m => m.HistoryPageModule)
    },
    {
        path: 'seller/home',
        loadChildren: () => import('./seller/home/home.module').then(m => m.HomePageModule)
    },
    {
        path: 'seller/profil',
        loadChildren: () => import('./seller/profil/profil.module').then(m => m.ProfilPageModule)
    },
    {
        path: 'seller/history',
        loadChildren: () => import('./seller/history/history.module').then(m => m.HistoryPageModule)
    },
    {
        path: 'prices',
        loadChildren: () => import('./prices/prices.module').then(m => m.PricesPageModule)
    },
    {
        path: 'seller/scanner',
        loadChildren: () => import('./seller/scanner/scanner.module').then(m => m.ScannerPageModule)
    },
    {
        path: 'about',
        loadChildren: () => import('./about/about.module').then(m => m.AboutPageModule)
    },
    {
        path: 'contact',
        loadChildren: () => import('./contact/contact.module').then(m => m.ContactPageModule)
    },
    {
        path: 'cgu',
        loadChildren: () => import('./cgv/cgv.module').then(m => m.CgvPageModule)
    },
    {
        path: 'seller/create-compagny',
        loadChildren: () => import('./seller/create-compagny/create-compagny.module').then(m => m.CreateCompagnyPageModule)
    },
    {
        path: 'seller/product',
        loadChildren: () => import('./seller/product/product.module').then(m => m.ProductPageModule)
    },
  {
    path: 'update-compagny',
    loadChildren: () => import('./seller/update-compagny/update-compagny.module').then( m => m.UpdateCompagnyPageModule)
  },
  {
    path: 'product-detail',
    loadChildren: () => import('./modal/product-detail/product-detail.module').then( m => m.ProductDetailPageModule)
  },
  {
    path: 'buyer/password',
    loadChildren: () => import('./buyer/password/password.module').then( m => m.PasswordPageModule)
  },
  {
    path: 'opening-time',
    loadChildren: () => import('./modal/opening-time/opening-time.module').then( m => m.OpeningTimePageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./cart/cart.module').then( m => m.CartPageModule)
  },
  {
    path: 'cgv-user',
    loadChildren: () => import('./cgv-user/cgv-user.module').then( m => m.CgvUserPageModule)
  },
  {
    path: 'cgv-seller',
    loadChildren: () => import('./cgv-seller/cgv-seller.module').then( m => m.CgvSellerPageModule)
  },
  {
    path: 'product',
    loadChildren: () => import('./modal/product/product.module').then( m => m.ProductPageModule)
  },








];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
